@mixin buttonBlock() {
    font-size: 12px;
    line-height: em(14px, 14px);
    padding: 8px 14px;
    transition: 0.3s ease;
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
    border: 1px solid $brand-color;
    border-radius: 3px;
}
@mixin buttonBlockHover() {
    text-decoration: none;
    color: #fff;
    background: $brand-color;
}