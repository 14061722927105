@mixin swiperButtons() {
    .swiper-button {
        position: absolute;
        z-index: 100;
        top: 50%;
        margin-top: -23px;
    }

    .swiper-button-prev { left: -10px; }
    .swiper-button-next { right: -10px; }

    @include media-breakpoint-up(sm) {
        .swiper-button-prev { left: -23px; }
        .swiper-button-next { right: -23px; }
    }

    @include media-breakpoint-up(xxl) {
        .swiper-button-prev { left: -50px; }
        .swiper-button-next { right: -50px; }
    }

    @media (min-width: 1500px) {
        .swiper-button-prev { left: -80px; }
        .swiper-button-next { right: -80px; }
    }

    &:not(.__slider_initialized__) .swiper-button { display: none !important; }
}