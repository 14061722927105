.filter {
	&__main {
		min-height: 50px;
		grid-template-columns: 1.5fr 1.3fr 164px 170px 190px 70px;
		border-color: $filterColor;
	}
	&__button {
		height: 50px;
		align-items: center;
		justify-content: center;
		height: 52px;
		&.f-main {
			margin-top: -1px;
			width: 72px;
		}
		&-text {
			
		}
	}
	&__option {
		&:not(:first-child) {
			border-color: $filterColor;
			@include media-breakpoint-down(xl) {
				border-left:0;
			}
		}
	}
	&__extend-container {
		grid-template-columns: 3fr 3fr 3fr 2fr 2fr;
		grid-template-rows: 1fr;
	    padding: 15px 15px 0px 15px;
	}
	&__column {
		display: flex;
		flex-direction: column;
	}
	&__tour-btn {
		border: none;
		border-radius: 3px;
		background: linear-gradient(180deg, var(--color1) 0%, var(--color2) 100%);
		font-size: 20px;
		font-weight: bold;
		line-height: 20px;
		text-transform: uppercase;
		text-align: center;
		padding-inline: 35px;
		padding-block: 15px;
		color: #fff;
		cursor: pointer;
		--color1: #179ba1;
		--color2: #29819d;
		white-space: nowrap;
		min-height: 30px;
		width: auto;
		&:hover {
			--color1: #{darken(#179ba1, 5%)};
			--color2: #{darken(#29819d, 5%)};
		}
	}
}
.checkbox {
	&.dyn-check-hide {
		display: none;
	}
}
.filter-calendar {
	&__container {
		.air-datepicker-cell{
			&:not(.-disabled-) {
				font-weight: bold;
			}
		}
	}
}
.filter-title {
    font-size: 14px;
    margin-bottom: 2px;
}
.filter-box {
	padding: 5px 0 5px 10px;
	flex: 1; /* consumes all free space (taking full height) */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* height: 100%; */
	&__scrollable {
		max-height: 216px;
		height: auto;
	}
}
