.checkbox {
	&__indicator {
		width: 18px;
		height: 18px;
	}
	&__content {
		font-size: 14px;
		padding-left: 5px;
	}
	&__label {
	    padding: 2px 5px 2px 0px;
	}
}


