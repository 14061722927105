.filter-form {
  display: flex;
  color: #000;
  border-radius: 6px;
  background: #fff;

  &__container {
    //display: flex;
    flex-grow: 1;
    //flex-wrap: wrap;
    padding: 6px 1rem;
  }

  &__box {
    padding: 5px 10px;
  }

  &__button-box {
    display: flex;
    flex-shrink: 0;
  }

  &__button {
    display: flex;
    flex-shrink: 0;
    min-width: 54px;
    padding: 0;
    color: #fff;
    border: none;
    border-radius: 6px;
    background: #e94444;

    > svg {
      width: 18px;
      height: 18px;
      margin: auto;
    }
  }

  @include media-breakpoint-down(sm) {
    &__box {
      &:nth-child(1) ~ & { border-top: 1px solid #bfbfbf; }
    }
  }

  @include media-breakpoint-up(sm) {
    &__container {
      display: flex;
      flex-wrap: wrap;
      column-gap: 1rem;
    }
    &__box {
      width: calc(50% - 0.5rem);
    }
  }

  @include media-breakpoint-only(sm) {
    &__box:nth-child(2) ~ &__box { border-top: 1px solid #bfbfbf; }
  }

  @include media-breakpoint-up(md) {
    &__box { width: calc(33% - 1rem); }
  }

  @include media-breakpoint-between(md, xxl) {
    &__box:nth-child(3) ~ &__box { border-top: 1px solid #bfbfbf; }
  }

  @include media-breakpoint-up(xxl) {
    &__container {
      padding-left: 0;
      padding-right: 0;
      column-gap: 0;
    }
    &__box {
      width: auto;
      padding: 0 10px;
      & + & { border-left: 1px solid #bfbfbf; }
    }
  }

  @include media-breakpoint-between(md, xxl) {
    &__box:nth-child(3) ~ &__box { border-top: 1px solid #bfbfbf; }
  }

}
