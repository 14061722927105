.filter-select-control {
  position: relative;
  display: flex;

  &::after {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 13px;
    height: 8px;
    content: '';
    transform: translateY(-50%);
    pointer-events: none;
    background: url("../img/icons/dropdown.svg");
    background-size: 100%;
  }

  &__element {
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    line-height: 15px;
    flex-grow: 1;
    padding: 0 (13px + 5px * 2 + 10px) 0 0;
    cursor: pointer;
    border: none;
    background: none;
    appearance: none;
  }
}
