.dropdown-icon {
  display: inline-block;
  width: 18px;
  height: 11px;
  margin: 0;
  padding: 0;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAJCAMAAAA1k+1bAAAAYFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////98JRy6AAAAH3RSTlMAAvkI+/XZx8O/mFBJPSbt082mchUE66ygeFlGNS8ftUR7KQAAAGFJREFUCNcdylkWwyAMQ1HZQCAkkLlzq/3vsj7W3z1PKDoF+MKUMjYOc3AdKzfMK4fnBVzHzYJdIpe3SFuorwDBIzL3X2HcAWMfNdaqWr9OaTtT4r2LEzit5xNw2j5jaa4/SUUEdTHGkBsAAAAASUVORK5CYII=');
  background-repeat: no-repeat;
  background-position: 4px center;
}
