.filter-children {
  &__button {
    font-size: 12px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    padding: 0;
    cursor: pointer;
    transition: 0.25s ease;
    transition-property: color, background-color;
    text-align: center;
    text-transform: uppercase;
    color: $brand-color;
    border: 1px solid $brand-color;
    border-radius: 3px;
    background: none;
    &:hover {
      color: #fff;
      background: $brand-color;
    }
    &::before {
      position: absolute;
      top: -1px;
      right: -20px;
      bottom: -1px;
      left: -20px;
      content: '';
      transition: opacity .25s ease;
      pointer-events: none;
      opacity: 0;
      background: $brand-color;
    }
    span { position: relative; }
  }

  &__list {
    max-height: 0;
    margin-right: -20px;
    margin-left: -20px;
    padding: 0;
    transition: .25s ease;
    transition-property: transform, opacity, padding, max-height;
    transform: scaleY(0);
    transform-origin: 0 0;
    opacity: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 35px;
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;

    &:hover {
      color: $brand-color;
      background: $background-light;
    }
  }

  &.is-open &__button::before { opacity: 1; }
  &.is-open &__button { color: #fff; }
  &.is-open &__list {
    max-height: 300px;
    padding: 10px 0;
    transform: scaleY(1);
    opacity: 1;
  }
}
