.about-vand {
  margin-block: 30px;

  &__cover {}
  &__img {
    display: block;
    max-width: 100%;
    margin: auto;
  }
  &__main {
    flex-grow: 1;
  }
  &__title {
    font-size: 22px;
    font-weight: 700;
    line-height: em(34px, 26px);
    text-transform: uppercase;

    span {
      color: $brand-color;
    }
  }
  &__content {
    font-size: 14px;
    line-height: em(24px, 16px);
    margin-block-start: 25px;
    p:last-child {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(md) {
    display: flex;
    &__cover {
      flex-shrink: 0;
      width: 28%;
    }
    &__img {
      margin: 0;
    }
    &__main {
      align-self: center;
      padding-inline-start: 30px;
      padding-inline-end: 0.5em;
    }
  }

  @include media-breakpoint-up(lg) {
    &__main {
      padding-inline-start: 40px;
    }
    &__content {
      font-size: 16px;
    }
  }

  @include media-breakpoint-up(xl) {
    &__title {
      font-size: 26px;
    }
  }
}
