$filter-radius: 10px;
$filter-border: 3px;

@import 'filter-expander';
@import 'filter-dropdown';
@import 'filter-button';
@import 'filter-criteria';
@import 'filter-children';

.added-child {
  display: flex;
  align-items: baseline;
  &__content {}
  &__delete {
    font-size: 12px;
    line-height: 1.2em;
    margin-left: 1em;
    cursor: pointer;
    text-decoration: none;
    color: #bd212a;
    border-bottom: 1px dotted currentColor;
  }
}

.filter {
  margin: 10px 0;
  border: $filter-border solid $brand-color;
  border-radius: $filter-radius;
  background: $background-light;

  &__main {
    display: grid;
    min-height: 70px;
    border-bottom: 1px solid $border-color;
    border-radius: 7px 7px 0 0;
    background: #fff;
    //grid-template-columns: 12fr 12fr 9fr 9fr 11fr 70px;
    grid-template-columns: 1fr 1fr 170px 170px 220px 70px;
    @include media-breakpoint-down(xl) {
      display: block;
    }
  }

  &__option {
    min-width: 0;
    &:not(:first-child) {
      border-left: 1px solid $border-color;
      @include media-breakpoint-down(xl) {
        border-top: 1px solid $border-color;
      }
    }
  }

  &__button {
    display: flex;
    width: 70px;
    height: 70px;
    color: #fff;
    border: none;
    border-top-right-radius: 5px;
    background: linear-gradient(180deg, #179ba1 0%, #29819d 100%);
    @include media-breakpoint-down(xl) {
      width: 100%;
      border-radius: 0;
    }
    .icon {
      width: 28px;
      height: 28px;
      margin: auto;
    }
  }

  &__extend-container {
    display: grid;
    padding: 20px;
    grid-template-columns: 3fr 3fr 2fr 2fr;
    grid-gap: 8px;
    @include media-breakpoint-down(xl) {
      display: block;
    }
  }

  &__column {
    &:not(:first-child) {
      @include media-breakpoint-down(xl) {
        margin-top: 10px;
      }
    }
  }

  &__expander { }
}

.filter-title {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &__icon {
    font-size: 18px;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    color: $mute-color;
  }
  &__content {}
}

.filter-box {
  padding: 10px 0;
  border: 1px solid $border-color;
  border-radius: 4px;
  background: #fff;

  &__scrollable {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: (36px * 6);

    @include scrollbar();
  }
}
