@import "../config/mixins/swiper-buttons";

.news-block {
    @include media-breakpoint-up(md) {
        padding: 20px 0;
    }
    &__link {
        text-decoration: none;
        color: var(--bs-body-color);
    }
    &__all {
        margin-top: 10px;
        text-align: right;
        a {
            border-bottom: 1px dashed;
            text-decoration: none;
        }
    }
    &__items {
        position: relative;
        .swiper { overflow: visible; }
        .swiper-slide { transition: opacity 0.3s ease; opacity: 0; }
        .swiper-slide-active { opacity: 1; }
    }
    &__head {
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 10px;
    }
    &__list {

    }
    &__item {
        border-radius: 5px;
        box-shadow: 5px 5px 8px 1px #888;
        padding-inline: 40px;
        align-self: stretch;
        height: auto;
        @include media-breakpoint-up(md) {
            padding: 10px;
        }
    }

    &__date {
        font-size: 12px;
        margin-bottom: 10px;
    }
    &__title {
        font-size: 14px;
        margin-bottom: 10px;
        font-weight: bold;
    }
    &__text {}

    &:not(.__slider_initialized__) &__item { max-width: 300px; }

    @include media-breakpoint-up(md) {
        .swiper-slide-active + .swiper-slide { opacity: 1; }
    }

    @include media-breakpoint-up(lg) {
        .swiper-slide-active + .swiper-slide + .swiper-slide { opacity: 1; }
    }

    @include media-breakpoint-up(xl) {
        .swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide { opacity: 1; }
    }

    @include swiperButtons();
}
