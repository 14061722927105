.search-results {
    tbody {
        &.sr-hot-tour {
            background: $primary;
            border: $filter-border solid #0D7076;
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
            span, a { color: #fff; }
            tr {
                &:hover {
                    td { background: $primary; }
                }
                @include media-breakpoint-down(lg) {
                    background: $primary;
                    border: 0;
                    .sr-td-hotel,
                    .sr-td-type,
                    .sr-td-food,
                    .sr-td-available {
                        .sr-mobile { color: #fff; }
                    }
                    &.sr-parent {
                        padding-top: 0;
                    }
                }
            }
            .hot-tour__header {
                td {
                    padding-block: 10px 0;
                    @include media-breakpoint-down(lg) {
                        font-size: 18px;
                        font-weight: bold;
                        padding-block: 0;
                    }
                }
                @include media-breakpoint-down(lg) {
                    padding-block: 5px;
                }
            }

        }
    }
}