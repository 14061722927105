.is-not-main-hotel {
    &.checkbox__label {
        cursor: not-allowed;
    }
    .checkbox__indicator {
        border: 1px solid #ccc;
    }
    .checkbox__content {
        color: #858585;
    }
}