@use 'sass:math';

.filter-criteria {
  position: relative;

  &__button {
    position: relative;
    height: 70px;
  }
  &__dropdown {
    position: absolute;
    z-index: 500;
    top: calc(100% + 1px);
    left: -1px;
    width: calc(100% + 2px);
    padding: 10px 20px;
    transition: 0.25s ease;
    transition-property: transform, opacity;
    transform: scaleY(0);
    transform-origin: 0 0;
    opacity: 0;
    border: 1px solid $border-color;
    border-top: none;
    background: #fff;
    box-shadow: 0 4px 20px 0 rgba(#007075, 0.05);
    > .filter-dropdown {
      margin-right: -20px;
      margin-left: -20px;
    }

    &_calendar {
      width: auto;
      padding-right: 10px;
      padding-left: 10px;
      .air-datepicker {
        border: none;
        --adp-color: #000;
        --adp-day-name-color: #858c8c;
        --adp-nav-arrow-color: #{$brand-color};
        --adp-cell-background-color-selected: #{$brand-color};
        --adp-cell-background-color-selected-hover: #{$brand-color};
        --adp-color-current-date: #{$brand-color};
        --adp-cell-border-radius: 0;
      }
      .air-datepicker-nav--title {
        font-weight: bold;
        i { font-weight: normal; }
      }
    }
  }
  &__item {
    margin: 10px 0;
  }

  &::before {
    position: absolute;
    top: math.div($filter-border, -1);
    right: -1px;
    bottom: -1px;
    left: -1px;
    content: '';
    transition: opacity 0.25s ease;
    opacity: 0;
    border: 1px solid $border-color;
    background: $background-light;
    box-shadow: 0 4px 20px 0 rgba(#007075, 0.05);
  }

  //==
  //== First place
  //== ======================================= ==//

  &_first::before {
    left: math.div($filter-border, -1);
    border-top-left-radius: ($filter-radius - 1px);
  }
  &_first &__dropdown {
    left: math.div($filter-border, -1);
    width: calc(100% + #{$filter-border + 1px});
  }
  &_first,
  &_first .filter-button {
    border-top-left-radius: 8px;
  }

  //==
  //== State open
  //== ======================================= ==//

  &.is-open { background: $background-light; }
  &.is-open::before { opacity: 1; }
  &.is-open &__dropdown {
    transform: scaleY(1);
    opacity: 1;
  }
  &.is-open .filter-button__top { color: $brand-color; }
}
