.apartments-available {
  display: flex;
  flex-direction: column;
  --apartments-available-color: #60bc6e;
  &__value {
    width: (6px * 5);
    height: 11px;
    background: linear-gradient(to right, var(--apartments-available-color) 4px, transparent 4px);
    background-size: 6px 100%;
    &_4 { width: (6px * 4); }
    &_3 { width: (6px * 3); --apartments-available-color: #dca768; }
    &_2 { width: (6px * 2); --apartments-available-color: #c64d0f; }
    &_1 { width: (6px * 1); --apartments-available-color: #930808; }
  }
  &__text {
    font-size: 12px;
    color: #858c8c;
  }
}
