.filter-button {
	padding: 5px 5px 10px 5px;
    height: 50px;
	&__top {
		padding-left: 8px;
	}
	&__placeholder {
		img {
			margin-left: 5px;
			width: 10px;
			cursor: pointer;
		}
	}
	&__value,
	&__control {
		margin-left: 28px;
	}
}
