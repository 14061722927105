.filter-dropdown {
	&__item {
		padding: 4px 20px;
		height: inherit;
		&.child-russia-country {
			&.showed {
				color: #1c9aa2;
			}
		}
	}
}