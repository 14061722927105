.about-vand {
    margin-block: 0px;
	&__title   { font-size: 23px; }
	&__content { margin-block-start: 0; }
}
.benefits-block {
    padding: 10px 40px;
    margin-block: 30px;
	&__title { 
		font-size: 23px;
		margin-block-start: 10px;
	}
	&__items { 
		margin-block: 10px; 
		@include media-breakpoint-down(md) {
			gap: 20px 20px;
		}
	}
}	
.rest-block {
    margin-block: 30px;
	&__title { font-size: 23px;}
	&__items {
		margin-block: 10px; 
		@include media-breakpoint-down(md) {
			gap: 20px 20px;
		}
	}
}
.services-block {
	@include media-breakpoint-up(md) {
		padding: 20px 40px;
		grid-template-columns: repeat(1, 1fr);
	}
	@include media-breakpoint-up(xl) {
		grid-template-columns: repeat(2, 1fr);
	}
	&__item {
		place-self: center;
	}
}
.services-block {
	&.s-new {
		@include media-breakpoint-up(md) {
			padding: 20px 40px;
			grid-template-columns: repeat(1, 1fr);
		}
		@include media-breakpoint-up(lg) {
			grid-template-columns: repeat(2, 1fr);
		}
		@include media-breakpoint-up(xl) {
			grid-template-columns: repeat(6, 1fr);
		}
		.service-item {
			flex-direction: column;
			text-align: center;
			place-self: auto;
			.service-item__content {
				margin-inline-start: 0;
			}
		}
	}
}
.popular-requests {
    padding: 10px 40px;
	margin-block: 30px;
	&__title { 
		font-size: 23px;
		margin-bottom: 10px;
	}
}
.popular-countries {
    margin-block: 30px;
	&__title { 
		font-size: 23px;
		margin-bottom: 10px;
	}
}
.section-divider {
    margin-block: 30px;
}

$swiperColor: #9c27b0;
.section { 
	position: relative;
	.swiper-button-prev {
		color: $swiperColor;
		left: -30px;
	}
	.swiper-button-next { 
		color: $swiperColor;
		right: -30px;
	}
}
