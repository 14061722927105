.popular-requests {
  padding: 60px 40px;
  border-radius: 10px;
  background: $background-light;
  margin-block: 60px;

  &__title {
    font-size: 26px;
    font-weight: bold;
    line-height: em(34px, 26px);
    margin-bottom: 40px;
    text-align: center;
    text-transform: uppercase;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
  }
}

.request-item {
  @include buttonBlock();
  &:hover {
    @include buttonBlockHover();
  }
}
