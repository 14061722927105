.service-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  --service-item-icon-color: #{$brand-color};
  --service-item-content-color: #000;

  &__icon,
  &__content {
    transition: color 0.3s ease;
  }

  &__icon {
    flex-shrink: 0;
    width: 70px;
    height: 70px;
    color: var(--service-item-icon-color);

    svg {
      display: block;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      object-fit: contain;
    }
  }

  &__content {
    font-size: 16px;
    color: var(--service-item-content-color);
    margin-inline-start: 25px;
  }

  &:hover {
    --service-item-icon-color: #000;
    --service-item-content-color: #{$brand-color};
  }
}
