.filter {
    .countries-wide {
        display: none;
    }
    @include media-breakpoint-up(xl) {
        .filter-criteria__country {
            .filter-criteria__dropdown {
               display: none;
            }
        }
        .main-filter-country-wide {
            position: relative;
            .countries-wide {
                background: #fff;
                border: 2px solid $brand-color;
                border-radius: 0 0 10px 10px;
                position: absolute;
                top: 51px;
                left: 0;
                column-count: 4;
                display: block;
                padding: 10px 20px;
                max-height: 81vh;
                overflow-x: hidden;
                overflow-y: auto;
                width: 100%;
                z-index: 700;
                @include scrollbar();
                &__item {
                    cursor: pointer;
                    &:hover {
                        color: #1c9aa2;
                        background: #f4fcfd;
                    }
                    &.child-russia-country {
                        display: none;
                        &.showed {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}