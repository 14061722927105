@mixin countryInputCriteriaPlaceholder {
	color: #000;
}
.filter-criteria {
	&__city {
		.filter-button {
			display: grid;
			grid-template-columns: 1.3fr 0.5fr;
			grid-template-rows: 2fr;
			grid-gap: 0px 5px;
			grid-template-areas:
				"citylb checks"
				"citynm checks";
			&__top {
				grid-area: citylb;
			}
			&__control {
				grid-area: citynm;
				margin-left: 8px;
			}
		}
		.checkbox-group {
			display: grid;
			grid-template-columns: .8fr 1fr;
			grid-template-rows: 2fr;
			grid-gap: 0px 2px;
			grid-area: checks;
			grid-template-areas: "with without"
				"withdop withdop";
			width: 137px;
			margin-top: -1px;
		}
		.checkbox {
			display: block;
			&.with {
				grid-area: with;
			}
			&.without {
				grid-area: without;
			}
			&.withdop {
				grid-area: withdop;
			}
		}
		.filter-dropdown {
			padding-top: 0;
			/*&__item {
				margin-top: 5px;
			}*/
		}
		.filter-criteria__dropdown {
			padding-block: 15px 5px;
			padding-block: 15px 5px;
		}
	}
	&__country {
		.filter-criteria{ 
			&__dropdown {
				padding-block: 6px 5px;
			}
		}
		.filter-button {
			&__control {
				margin-left: 8px;
			}
			&__element {
				&::-webkit-input-placeholder { @include countryInputCriteriaPlaceholder; }
				&::-moz-placeholder { @include countryInputCriteriaPlaceholder }
				&:-moz-placeholder { @include countryInputCriteriaPlaceholder; }
				&:-ms-input-placeholder { @include countryInputCriteriaPlaceholder; }
			}
		}
		.filter-dropdown {
			&__container {
				.child-russia-country:not(.showed) {
					display: none;
				}
			}
		}
	}
	&__tourists {
		.spin{
			&__label {
				margin-right: 7px;
			}
			&__input {
				width: 25px;
			}
		}
		.filter-children {
			&__button-block {
				padding: 5px 20px;
			}
			&__list {
				max-height: 0;
				margin-right: 0px;
				margin-left: -1px;
				padding: 0 0 10px 0;
			}
			&__item {
				height: 25px;
			}
		}
	}
	&__tourists &__dropdown {
		padding: 10px 0px;
		@include media-breakpoint-down(md) {
			position: static;
			display: none;
		}
	}
	&__tourists.is-open &__dropdown {
		@include media-breakpoint-down(md) {
			display: block;
		}
	}
	&__tourists &__item {
		margin: 10px 20px;
	}
}