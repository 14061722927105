.filter-dropdown {
  padding: 10px 0;
  border-top: none;
  background: #fff;

  &__container {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 270px;

    @include scrollbar();
  }

  &__item {
    font-size: 14px;
    line-height: em(16px, 14px);
    display: flex;
    align-items: center;
    height: 35px;
    padding: 5px 20px;
    cursor: pointer;
    &:hover {
      color: $brand-color;
      background: $background-light;
    }
  }
}
