.reviews-block {
    .swiper-button-prev {
        @include media-breakpoint-down(md) {left: -10px; }
    }
    .swiper-button-next {
        @include media-breakpoint-down(md) {right: -10px; }
    }
    @include media-breakpoint-up(md) {
        .swiper-slide-active + .swiper-slide { opacity: 1; }
    }
    @include media-breakpoint-up(lg) {
        .swiper-slide-active + .swiper-slide + .swiper-slide { opacity: 1; }
    }
}