@use "sass:math";

.spin {
  display: flex;
  align-items: center;

  &__label {
    margin-right: 15px;
  }
  &__button {
    position: relative;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    color: $brand-color;
    border: none;
    border-radius: 4px;
    background: $background-light;
    &::before,
    &::after {
      position: absolute;
      top: math.div(30px - 2px, 2);
      left: math.div(30px - 14px, 2);
      width: 14px;
      height: 2px;
      content: '';
      background: currentColor;
    }
    &_minus { }
    &_plus::after {
      content: '';
      transform: rotate(90deg);
    }

    &:hover {
      background: #d0e5e7;
    }

    &:active {
      color: #fff;
      background: #1c9aa2;
    }
  }
  &__input {
    font-weight: bold;
    width: 40px;
    padding: 0;
    text-align: center;
    border: none;
    background: none;
    appearance: none;
  }
}
