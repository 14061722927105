.search-results {
  font-size: 14px;
  position: relative;
  overflow: auto;
  border: 1px solid $border-color;
  border-radius: 3px;
  --sr-price-font-size: 18px;
  --sr-old-price-font-size: 14px;

  a {
    text-decoration: none;
  }

  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
  }
  td, th {
    font-size: 14px;
    padding-left: 20px;
    padding-block: 17px;
  }

  thead {
    tr {
      th {
        font-size: 14px;
        font-weight: normal;
        line-height: em(16, 14);
        color: $mute-color;
        border-bottom: 1px solid $border-color;
        background: $background-light;
        padding-block: 20px;
      }
    }
  }

  tbody {
    border-bottom: 1px solid $border-color;
    tr {
      transition: transform 0.25s ease;
      @include media-breakpoint-up(lg) {
        &:hover {
          --sr-details-width: 32px;

          td {
            background: $background-light;
          }
        }
      }
    }

    &.is-open td {
      background: $background-light;
    }

    &:not(.is-open) tr.sr-children {
      display: none;
    }
  }

  .sr-date {
    color: $mute-color;
  }
  .sr-details {
    width: 32px + 10px;
    padding: 0;
  }

  --sr-details-width: 16px;
}

.sr-price {
  font-size: var(--sr-price-font-size, 18px);
  font-weight: 700;
  line-height: em(19px, 18px);
  white-space: nowrap;
}

.sr-price-highlight { color: #b81d26; }

.sr-price-muted { color: #6a7071; }

.sr-old-price {
  font-size: var(--sr-old-price-font-size, 14px);
  font-weight: 400;
  line-height: em(19px, 18px);
  white-space: nowrap;
  text-decoration: line-through;
}

.sr-details {
  text-align: right;
  vertical-align: middle;
}

.sr-details-button {
  display: inline-flex;
  width: var(--sr-details-width, 16px);
  height: 50px;
  transition: 0.3s ease;
  color: #fff !important;
  border: none;
  background: $primary;
  svg {
    flex-shrink: 0;
    width: 4px;
    height: 10px;
    margin: auto;
    transition: 0.3s ease;
  }

  &:hover {
    background: lighten($primary, 5%);
    --sr-details-width: 32px;
  }

  @include media-breakpoint-down(lg) {
    font-size: 1.2em;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 10px;
    gap: 10px;
    svg {
      margin: 0;
    }
  }
}

.sr-mobile {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.sr-desktop {
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.search-results {
  @include media-breakpoint-down(lg) {
    overflow: visible;
    border: none;
    --sr-price-font-size: 28px;
    --sr-old-price-font-size: 20px;

    thead { display: none; }
    table, tbody, tfoot, tr, td, th {
      display: block;
      padding: 0;
      border: none;
    }
    table {
      display: grid;
      grid-template-columns: 1fr 1fr;
      //grid-template-columns: 50% 50%;
      grid-gap: 1rem;
    }
    tbody {
      display: grid;
      grid-template-columns: 100%;
    }
    tr {
      display: grid;
      align-content: start;
      padding: 1rem;
      border: 1px solid #cedfe6;
      border-radius: 4px;
      background: #f4fcfd;
      box-shadow: 0 1px 5px rgba(#000, 0.1);
      grid-template-rows: repeat(5, auto) 1fr auto;
      grid-template-columns: 3fr 2fr;
    }
    td {
      order: 100;
      padding: 0.25rem 0;
      //border: 1px solid red;
    }


    .sr-preview-image {
      display: block;
      width: 100%;
      max-width: 100%;
      max-height: 280px;
      object-fit: cover;
    }

    .sr-td-preview {
      overflow: hidden;
      order: 0;
      margin: -1rem;
      margin-bottom: 0;
      padding: 0;
      border-radius: 3px 3px 0 0;
      grid-column: span 2;
    }
    .sr-td-title {
      font-size: 1.2em;
      font-weight: bold;
      line-height: 1.1;
      order: 1;
      margin-top: 1rem;
      grid-column: span 2;
      .sr-mobile { color: $mute-color; }
    }
    .sr-td-hotel {
      font-size: 1.1em;
      order: 2;
      grid-column: span 2;
      .sr-mobile { color: $mute-color; }
    }
    .sr-td-type {
      order: 3;
      grid-column: span 2;
      .sr-mobile { color: $mute-color; }
    }
    .sr-date {
      order: 4;
    }
    .sr-td-duration {
      order: 5;
    }
    .sr-td-available {
      display: flex;
      align-items: baseline;
      order: 6;
      gap: 0.25em;
      .apartments-available { align-items: center; flex-direction: row; gap: 0.25em; }
      .sr-mobile { color: $mute-color; }
    }
    .sr-td-food {
      order: 7;
      .sr-mobile { color: $mute-color; }
    }
    .sr-td-prices {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      order: 8;
      padding-top: 0.75rem;
      grid-column: span 2;
    }
    .sr-tickets {
      display: none;
      order: 9;
      padding-top: 0;
      grid-column: span 2;
    }
    .sr-details {
      //display: flex;
      //align-items: flex-end;
      //justify-content: flex-end;
      order: 10;
      width: auto;
      padding-top: 0.75rem;
      grid-column: span 2;
    }

    tbody.is-open {
      display: grid;
      grid-template-columns: 100%;
      grid-gap: 0.25rem;
      grid-column: span 2;

      tr {
        border-color: #1c9aa2;
      }

      .sr-summary {
        //grid-column: span 2;
      }
    }
  }
  @include media-breakpoint-down(md) {
    table {
      grid-template-columns: 100%;
    }
    tbody.is-open {
      grid-column: span 1;
    }
  }
}
