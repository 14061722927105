.dynhotel-desc {
    &__gallery {
        margin-bottom: 15px;
    }
    &__gallery-items {
        position: relative;
        .swiper {
            max-height: 200px;
            overflow: hidden;
            @include media-breakpoint-up(sm) {
                max-height: 120px;
            }
        }
    }
    &__gallery-image {
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        height: 120px;
    }
    .swiper-button {
        position: absolute;
        z-index: 100;
        top: 50%;
        margin-top: -23px;
    }
    .swiper-button-prev { left: -10px; }
    .swiper-button-next { right: -10px; }

    @include media-breakpoint-up(sm) {
        .swiper-button-prev { left: -23px; }
        .swiper-button-next { right: -23px; }
    }
}