@import "../config/mixins/swiper-buttons";

.popular-countries {
  margin-block: 60px;

  &__title {
    font-size: 26px;
    font-weight: bold;
    line-height: em(34px, 26px);
    margin-bottom: 40px;
    text-align: center;
    text-transform: uppercase;
  }

  &__items {
    position: relative;
    .swiper { overflow: visible; }
    .swiper-slide { transition: opacity 0.3s ease; opacity: 0; }
    .swiper-slide-active { opacity: 1; }
  }

  &:not(.__slider_initialized__) &__item { max-width: 300px; }

  @include media-breakpoint-up(md) {
    .swiper-slide-active + .swiper-slide { opacity: 1; }
  }

  @include media-breakpoint-up(lg) {
    .swiper-slide-active + .swiper-slide + .swiper-slide { opacity: 1; }
  }

  @include media-breakpoint-up(xl) {
    .swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide { opacity: 1; }
  }

  @include swiperButtons();
}

.popular-country {
  display: block;
  overflow: hidden;
  padding-bottom: 25px;
  transition: box-shadow 0.25s ease;
  text-decoration: none !important;
  border: 1px solid $border-color;
  border-radius: 4px;

  &__cover { }
  &__img {
    display: block;
    width: 100%;
  }
  &__main {
    position: relative;
    margin-top: 15px;
    text-align: center;
  }
  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: em(28px, 18px);
    color: #000;
  }
  &__info {
    font-size: 16px;
    line-height: em(26px, 16px);
    color: #000;
    span {
      font-size: em(18px, 16px);
      font-weight: bold;
      color: $brand-color;
    }
  }
  &__flag {
    position: absolute;
    top: 50%;
    right: 0;
    width: 34px;
    transform: translateY(-50%)
  }

  &:hover { box-shadow: 0 4px 20px 0 rgba(#007075, 0.1); }
  &:hover &__title { color: $brand-color; }
}
