@mixin announcesTitle() {
	border-style: solid;
	border-color: transparent;
	border-left-color: #000;
	position: absolute;
	left: 100%;
	top: 50%;
	content: " ";
	opacity: 0;
	pointer-events: none;
	height: 0;
	width: 0;
}
.announces-block {
	position: relative;
	aspect-ratio: 1278 / 485;
	width: 100%;
	&__links {
		position: absolute;
		top: 0;
		left: -1px;
		z-index: 5;
	}
	&__head {
		background: rgba(0, 0, 0, 0.6);
		margin: 10px;
		white-space: nowrap;
		padding: 10px 12px;
		line-height: 0;
	}
	&__head-title {
		font-family: 'PT Sans', sans-serif;
		font-weight: bold;
		font-size: 23px;
		position: relative;
		overflow: hidden;
		border: 0 !important;
		text-transform: uppercase;
		text-align: center;
		color: #FFF;
		line-height: 18px;
		padding: 0;
		margin: 0;
	}
	&__announce {
		position: relative;
		margin-bottom: 1px;
		width: 186px;
		display: block;
		&:hover, 
		&.acive {
			background-position: 0 -41px;
			color: #ffffff !important;
			text-decoration: none;
		}
	}
	&__announce-info {
		background: rgba(0, 0, 0, 0.8);
		font-family: 'PT Sans', sans-serif;
		font-weight: 500;
		font-size: 15px;
		line-height: 18px;
		color: #FFF;
		padding: 7px;
		position: absolute;
		top: 0;
		right: -260px;
		max-width: 250px;
		display: none;
		width: 250px;
	}
	&__announce-title {
		text-transform: uppercase;
		/*font-family: 'PT Sans', sans-serif;*/
		/*font-size: 16px;*/
		display: inline-block;
		position: relative;
		padding: 1px 10px;
		width: 180px;
		font-weight: bold;
		color: #FFF;
		background: rgba(0, 0, 0, 0.7);
		&:after {
			@include announcesTitle();
			border-width: 10px;
			margin-top: -10px;
		}
		&:before {
			@include announcesTitle();
			border-width: 14px;
			margin-top: -14px;
		}
	}
	//==
	//== Hover & active states
	//== ======================================= ==//

	&__announce:hover  &__announce-title,
	&__announce.active &__announce-title,
	&__announce-title:hover {
		background: #000;
		color: #00fefe;
		cursor: pointer;
	}
	&__announce:hover  &__announce-info,
	&__announce.active &__announce-info {
		display: block;
	}
	&__announce:hover  &__announce-title:after,
	&__announce:hover  &__announce-title:before,
	&__announce.active &__announce-title:after,
	&__announce.active &__announce-title:before,
	&__announce-title:hover:after,
	&__announce-title:hover:before {
		opacity: 1;
	}
	&__announce:last-child &__announce-info {
		bottom: 0;
		top: auto;
	}
	&__mobile-links {
		.swiper-button {
			position: absolute;
			top: 50%;
			margin-top: -25px;
			z-index: 100;
		}
		.swiper-button-prev { left: -5px; }
		.swiper-button-next { right: -5px; }
	}
	&__mobile-item {
		&__images { width: 100%; }
	}
}







