.filter-button {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 5px;
  cursor: pointer;
  transition: 0.25s ease background-color;
  border: none;
  background: none;

  &__top {
    display: flex;
    padding-right: 20px;
    padding-left: 20px;
    transition: 0.25s ease color;
    color: $mute-color;
  }
  &__iconbox {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: flex-end;
    width: 20px;
  }
  &__icon {
    width: 18px;
    height: 18px;
  }
  &__placeholder {
    padding-left: 5px;
  }
  &__value,
  &__control {
    display: block;
    height: 22px;
    margin-left: 40px;
    padding-left: 5px;
    text-align: left;
  }
  &__control {
    padding-right: 20px;
  }

  &__element {
    font-size: 16px;
    line-height: em(18px, 16px);
    width: 100%;
    height: 22px;
    padding: 0;
    cursor: pointer;
    text-indent: 0;
    color: #000;
    border: none;
    background: none;
    &:focus {
      outline: 1px dotted transparent;
      outline-offset: 3px;
    }
  }

  &:hover { background: $background-light; }
  &:hover &__top { color: $brand-color; }

  &:focus {
    outline: 1px dotted transparent !important;
    outline-offset: -4px;
  }
}
