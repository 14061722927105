//==
//== Configuration
//== ======================================= ==//

@import "../config/mixins/debug";
@import "../config/mixins/font-size";
@import "../config/mixins/scrollbars";
@import "node_modules/bootstrap/scss/functions";
@import "../config/variables";
@import "node_modules/bootstrap/scss/mixins";

//==
//== Control Elements
//== ======================================= ==//

@import "../controls/filter-select-control";
@import "../controls/spin";

//==
//== Blocks
//== ======================================= ==//

@import "../blocks/dropdown-icon";
@import "../blocks/apartments-available";
@import "../blocks/filter";
@import "../blocks/filter-form";
@import "../blocks/filter-option";
@import "../blocks/hotel-rating";

@import "../blocks/currency-switch";
@import "../blocks/search-results";

:root {
	--svg-arrow-circle-background: #E66969;
	--svg-arrow-circle-color: #fff;
	--svg-check-circle-background: #1C9AA2;
	--svg-check-circle-color: #fff;
}

$filterColor: #9ea1a5;

@import "checkbox";
@import "filter";
@import "filter-criteria";
@import "filter-button";
@import "filter-dropdown";
@import "filter-expander";
@import "filter-no-main-hotel";
@import "filter-countries-wide";
@import "filter-nights-list";
@import "search-results";
@import "hot-tour";

@import "desc-gallery";
