.filter-expander {
  font-size: 14px;
  line-height: em(20px, 14px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  text-align: center;
  color: $brand-color;
  border: none;
  background: none;

  &__content {}
  &__icon {
    flex-shrink: 0;
    width: 6px;
    height: 6px;
    margin-left: 7px;
    transform: translateY(1px);
  }
}
