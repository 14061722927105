@use 'sass:math';
.filter-criteria {
    &__dropdown {
        &_nights {
            width: auto;
            padding-inline: 10px;
            --ngt-color: #000;
            --ngt-day-name-color: #858c8c;
            --ngt-nav-arrow-color: #{$brand-color};
            --ngt-cell-background-color-selected: #{$brand-color};
            --ngt-cell-background-color-selected-hover: #{$brand-color};
            --ngt-color-current-date: #{$brand-color};
            --ngt-cell-border-radius: 0;
            --ngt-night-cols-count: 7;
            --ngt-night-cell-width: 1fr;
            --ngt-night-cell-height: 32px;
            --ngt-width: calc(var(--ngt-night-cell-height) * var(--ngt-night-cols-count));
            --ngt-color-disabled: #AEAEAE;
            --ngt-color-disabled-in-range: #939393;
            --ngt-background-color-hover: #f0f0f0;
            --ngt-accent-color: #4EB5E6;
            --ngt-cell-background-color-in-range: rgba(92, 196, 239, 0.1);
            --ngt-cell-background-color-in-range-hover: rgba(92, 196, 239, 0.2);
            --ngt-cell-border-color-in-range: var(--ngt-cell-background-color-selected);
        }
    }
}
.nights-container {
    &__list {
        display: grid;
        grid-template-columns: repeat(var(--ngt-night-cols-count), var(--ngt-night-cell-width));
        grid-auto-rows: var(--ngt-night-cell-height);
        width: var(--ngt-width);
        &_ceil{
            border-radius: var(--ngt-cell-border-radius);
            box-sizing: border-box;
            cursor: pointer;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            z-index: 1;
            &:not(.-disabled-) {
                font-weight: bold;
            }
            &:hover { color: var(--ngt-color); background: var(--ngt-background-color-hover); }
            &.-out-range-,
            &.-disabled- {
                cursor: default;
                color: var(--ngt-color-disabled);
                &.-in-range- { color: var(--ngt-color-disabled-in-range); }
            }
            &.-selected- {
                background: var(--ngt-cell-background-color-selected);
                color: #fff;
                border: none;
            }
            &.-in-range- {
                background: var(--ngt-cell-background-color-in-range);
                border-radius: 0;
                &:hover { background: var(--ngt-cell-background-color-in-range-hover); }
            }
            &.-range-to-, &.-range-from-{ border: 1px solid var(--ngt-cell-border-color-in-range); }
            &.-range-from- { border-radius: var(--ngt-cell-border-radius) 0 0 var(--ngt-cell-border-radius); }
            &.-range-to-   { border-radius: 0 var(--ngt-cell-border-radius) var(--ngt-cell-border-radius) 0; }
        }
    }
}
.filter-criteria__nights .filter-criteria__dropdown {
/*    transform: scaleY(1);
    opacity: 1;*/
}