.currency-switch {
  &__title {
    font-size: 14px;
    font-weight: normal;
    white-space: nowrap;
    color: #0d7076;
  }
  &__items {}
  &__button {
    &:not(.btn-primary) {
      color: $primary;
      border: 1px solid $light;
      &:hover {
        border-color: $primary;
      }
    }
  }
}
