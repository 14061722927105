.services-block {
  display: grid;
  padding: 20px 20px;
  border-radius: 10px;
  background: $background-light;
  margin-block: 10px;
  grid-gap: 20px;

  @include media-breakpoint-up(md) {
    padding: 30px 40px;
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(4, 1fr);
  }
}
