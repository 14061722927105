.hotel-rating {
  display: flex;
  align-items: center;
  &__icon {
    font-size: 16px;
    width: 16px;
    height: 16px;
    margin-left: 7px;
    color: #ffc700;
  }
}
