.result {
    margin-top: 20px;
}
.search-results-block {
	margin-top: 35px;
	@include media-breakpoint-down(lg) { margin-top: 20px; }
	h2 {
		font-size: 1.5rem;
		@include media-breakpoint-down(lg) {
			font-size: 1.15rem;
			margin-bottom: 0px; 
		}
	}
}
.search-results {
	table { }
	thead {
		tr {
			th { padding-block: 8px; }
		}
	}
	tbody {
		&.is-open tr.sr-parent {
			display: none;
		}

		tr {
			td {
				padding-block: 12px;
				@include media-breakpoint-down(lg) { padding-block: 0px; }
			}
			&.sr-children {
				td {
					padding-block: 5px;
					@include media-breakpoint-down(lg) { padding-block: 0px; }
				}
				.sr-td-duration {
					text-align: center;
				}
			}
		}
	}
	@include media-breakpoint-down(lg) {
		.sr-td-hotel {
			br { display: none; }
		}
		.sr-td-prices {
			text-align: center;
		}
		.sr-parent {
			.sr-price {
				font-size: var(--sr-price-font-size, 18px);
				font-weight: 700;
			}

		}
		.sr-children {
			.sr-td-prices {

				.avia {
					margin-bottom: 5px;
				}
			}
		}
		.sr-price-link {
			min-width: inherit;
			font-weight: normal;
			font-size: 14px;
			display: inherit;
			line-height: inherit;
		}
	}
	@include media-breakpoint-only(md) {
		.sr-date { grid-column: span 2; }
		.sr-td-duration { grid-column: span 2; }
	}
	@include media-breakpoint-only(xs) {
		.sr-date { grid-column: span 2; }
		.sr-td-duration { grid-column: span 2; }
	}
}
.sr-price {
	text-transform: lowercase;
	min-width: 75px;
}
.sr-parent {
	.sr-price {
		font-size: 14px;
		font-weight: normal;
	}
}
.sr-details {
	a {
		svg {
			width: 6px;
			height: 15px;
		}
	}
}
.sr-price-link {
	font-size: 17px;
	font-weight: 700;
	line-height: 1.0555555556em;
    display: flex;
    min-width: 98px;
	span {
		margin-right: 3px;
	}
	.arrow.icon {
		vertical-align: middle;
		margin-top: 6px;
		width: 9px;
		height: 9px;
	}
	.is-open & {
		.arrow.icon {
			transform: rotate(180deg);
		}
	}
}
.sr-add-fl-btn,
.sr-out-fl-btn {
	white-space: nowrap;
	line-height: 1.2;
	min-width: 120px;
	margin-bottom: 5px;
	@include media-breakpoint-down(lg) {
		text-align: center;
		display: inline-block;
		width: 100%;
		text-decoration: underline;
	}
}
#modal-dyn-info {
	.ab-caption {
		font-size: 16px;
		color: #0b8b94;
		margin-block: 13px 5px;
		line-height: 14px;
	}
	.ab-description {
		font-size: 14px;
		line-height: 14px;
		color: #555;
	}
}
