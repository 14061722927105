.filter-expander {
	font-weight: bold;
	height: 25px;
	&__icon {
		width: 10px;
		height: 10px;
		&.is-open {
			transform: rotateX(180deg);
		}
	}
}
