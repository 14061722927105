@import "../config/mixins/swiper-buttons";

.reviews-block {
  margin-block: 30px;
  &__title {
    font-size: 23px;
    font-weight: bold;
    line-height: em(34px, 26px);
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
  }

  &__items {
    position: relative;
    .swiper { overflow: visible; }
    .swiper-slide { transition: opacity 0.3s ease; opacity: 0; }
    .swiper-slide-active { opacity: 1; }
  }

  &__item {
    display: flex;
    align-self: stretch;
    height: auto;
  }

  &:not(.__slider_initialized__) &__item { min-width: 200px; max-width: 300px; }

  @include swiperButtons();
}

.review-item {
  padding: 30px;
  transition: box-shadow 0.25s ease;
  border: 1px solid $border-color;
  border-radius: 10px;
  background: #fff;

  &:hover {
    box-shadow: 0 4px 20px 0 rgba(#007075, 0.1);
  }

  &__header {}
  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: em(24px, 16px);
  }
  &__date {
    font-size: 14px;
    color: $mute-color;
  }
  &__country {
    font-size: 14px;
    position: relative;
    width: 100%;
    margin-top: 5px;
    padding-left: 20px;
    color: $mute-color;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      content: '';
      background: url(../img/icons/location.svg);
      background-size: contain;
    }
  }
  &__content {
    font-size: 14px;
    line-height: em(22px, 14px);
    margin-top: 15px;
  }
  &__readmore {
    a {
      text-decoration: none;
      color: $brand-color;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    &__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
