.rest-block {
  margin-block: 60px;

  &__title {
    font-size: 26px;
    line-height: em(34px, 26px);
    text-align: center;
    text-transform: uppercase;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-block: 30px;
  }

  &__item {
    width: 100%;
  }

  @include media-breakpoint-up(sm) {
    &__items { gap: 20px; }
    &__item { width: calc(50% - 10px); }
  }

  @include media-breakpoint-up(md) {
    &__items { gap: 30px 20px; }
    &__item { width: calc(33.3333% - 13.3333px); }
  }

  @include media-breakpoint-up(lg) {
    //&__item { width: calc(25% - 15px); }
  }

  @include media-breakpoint-up(xl) {
    &__item { width: calc(20% - 16px); }
  }
}

.rest-item {
  display: flex;
  overflow: hidden;
  align-items: center;
  text-decoration: none;
  --rest-item-icon-color: #{$brand-color};
  --rest-item-content-color: #000;

  &__icon,
  &__content {
    transition: color 0.3s ease;
  }

  &__icon {
    flex-shrink: 0;
    width: 70px;
    height: 70px;
    color: var(--rest-item-icon-color);

    svg {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__content {
    font-size: 16px;
    line-height: em(24px, 18px);
    flex-grow: 1;
    color: var(--rest-item-content-color);
    padding-inline-start: 18px;
  }


  &:hover {
    --rest-item-icon-color: #000;
    --rest-item-content-color: #{$brand-color};
  }
}
